.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--milka {
  --backgroundColor: rgba(79, 102, 255, 0.31);
  background-color: rgba(79, 102, 255, 0.31);
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--milka {
  --color: rgba(79, 102, 255, 0.31);
  color: rgba(79, 102, 255, 0.31);
}
.hSpace--mini {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}
.hSpace--small {
  padding-left: 0.25em;
  padding-right: 0.25em;
}
.hSpace--default {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.hSpace--medium {
  padding-left: 1rem;
  padding-right: 1rem;
}
.hSpace--large {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.vSpace--mini {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.vSpace--small {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
.vSpace--default {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.vSpace--medium {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.vSpace--large {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.vhSpace--mini {
  padding: 0.1rem;
}
.vhSpace--small {
  padding: 0.25em;
}
.vhSpace--default {
  padding: 0.5rem;
}
.vhSpace--medium {
  padding: 1rem;
}
.vhSpace--large {
  padding: 2.5rem;
}
@media (max-width: 750px ) {
}[showBorders3] .MainNav * {
  background-color: rgba(0, 128, 0, 0.25);
  outline: 1px solid rgba(0, 128, 0, 0.25);
}
.MainNav {
  position: fixed;
  top: 0;
  left: 0px;
  min-height: 100vh;
  transition: all 0.25s ease;
  z-index: 100;
  display: flex;
  flex-direction: column;
}
@media (min-width: 1023px ) {
  .MainNav {
    padding: 24px 33px;
  }
}
@media (max-width: 1023px ) {
  .MainNav {
    padding: 18px 16px;
  }
}
@media (max-width: 750px ) {
  .MainNav {
    padding: 16px 12px;
    width: 100%;
  }
}
.MainNav--isNotOpen {
  transform: translateX(-100%);
  pointer-events: none;
}
.MainNav--isOpen {
  transform: translateX(0%);
  pointer-events: auto;
}
.MainNav__header {
  display: flex;
  justify-content: space-between;
  flex-grow: 0;
  flex-shrink: 0;
}
.MainNav__body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-bottom: 1.5em;
}
@media (max-width: 1023px ) {
  .MainNav__body {
    justify-content: center;
    padding-top: 0em;
  }
}
@media (min-width: 1023px ) {
  .MainNav__body {
    justify-content: flex-start;
    padding-top: 0.5em;
  }
}
@media (min-width: 1023px ) {
  .MainNav__logo {
    display: none;
    height: 50px;
    width: 140px;
  }
}
@media (max-width: 1023px ) {
  .MainNav__logo {
    display: none;
    height: 40px;
    width: 105px;
  }
}
@media (max-width: 750px ) {
  .MainNav__logo {
    display: block;
    height: 40px;
    width: 105px;
  }
}
.MainNav__menuLinks {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.MainNav__menuLink {
  display: flex;
  flex-grow: 1;
}
.MainNav__menuLink span {
  display: block;
}
.MainNav__menuLinkIndent {
  width: 1em;
}
.MainNav {
  background-color: var(--color-schwarz);
}
@media (min-width: 1023px ) {
  .MainNav {
    font-size: 40px;
    line-height: 1em;
  }
}
@media (max-width: 1023px ) {
  .MainNav {
    font-size: 40px;
    line-height: 1em;
  }
}
@media (max-width: 750px ) {
  .MainNav {
    font-size: 40px;
    line-height: 1em;
  }
}
.MainNav__logo {
  background-image: url('../assets/images/tmo-logo.svg');
  background-repeat: no-repeat;
  background-size: contain;
  filter: invert(100%);
  overflow: hidden;
  text-indent: 50vw;
}
.MainNav__menuLink {
  color: var(--color-weiss);
}
