























































































































.MhContentModuleWrapper { // debug
	[showBorders2] & {}
}
.MhContentModuleWrapper { // vars
	//
}
.MhContentModuleWrapper { // layout
	&__errorMsg {
		margin: 0.25em 0;
		padding: 0.5em;
	}
}
.MhContentModuleWrapper { // styling
	&__errorMsg {
		background-color: fade( red, 25 );
		border: 1px solid fade( red, 50 );
		border-radius: 0.25em;
	}
}
