.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--milka {
  --backgroundColor: rgba(79, 102, 255, 0.31);
  background-color: rgba(79, 102, 255, 0.31);
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--milka {
  --color: rgba(79, 102, 255, 0.31);
  color: rgba(79, 102, 255, 0.31);
}
.hSpace--mini {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}
.hSpace--small {
  padding-left: 0.25em;
  padding-right: 0.25em;
}
.hSpace--default {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.hSpace--medium {
  padding-left: 1rem;
  padding-right: 1rem;
}
.hSpace--large {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.vSpace--mini {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.vSpace--small {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
.vSpace--default {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.vSpace--medium {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.vSpace--large {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.vhSpace--mini {
  padding: 0.1rem;
}
.vhSpace--small {
  padding: 0.25em;
}
.vhSpace--default {
  padding: 0.5rem;
}
.vhSpace--medium {
  padding: 1rem;
}
.vhSpace--large {
  padding: 2.5rem;
}
@media (max-width: 750px ) {
}.ImageAndText__inner {
  padding-right: var(--app-content-hPadding);
  padding-left: var(--app-content-hPadding);
  max-width: var(--app-content-maxWidth);
  margin: 0 auto;
  display: grid;
  gap: var(--app-grid-gap);
  grid-template-columns: repeat(var(--app-grid-columns), minmax(0, 1fr));
}
.ImageAndText__imageWrapper {
  grid-area: a;
}
.ImageAndText__textWrapper {
  grid-area: b;
}
@media (max-width: 1023px ) {
  .ImageAndText--hasNoHeadline .ImageAndText__inner {
    grid-template-areas: ". a a a a a a ." ". . b b b b b b";
  }
}
@media (max-width: 750px ) {
  .ImageAndText--hasNoHeadline .ImageAndText__inner {
    grid-template-areas: "a a a a a a a a" "b b b b b b b b";
  }
}
@media (min-width: 1023px ) {
  .ImageAndText--hasNoHeadline.ImageAndText--imagePosition-left .ImageAndText__inner {
    grid-template-areas: "a a a a . b b b b b b b";
  }
}
@media (min-width: 1023px ) {
  .ImageAndText--hasNoHeadline.ImageAndText--imagePosition-right .ImageAndText__inner {
    grid-template-areas: "b b b b b b b . a a a a";
  }
}
@media (max-width: 1023px ) {
  .ImageAndText--hasHeadline .ImageAndText__inner {
    grid-template-areas: ". a a a a a a ." "b b b b b b b b";
  }
}
@media (max-width: 750px ) {
  .ImageAndText--hasHeadline .ImageAndText__inner {
    grid-template-areas: "a a a a a a a a" "b b b b b b b b";
  }
}
.ImageAndText--hasHeadline .ImageAndText__textWrapper {
  display: grid;
  gap: var(--app-grid-gap);
}
@media (min-width: 1023px ) {
  .ImageAndText--hasHeadline .ImageAndText__textWrapper {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .ImageAndText--hasHeadline .ImageAndText__textWrapper .ImageAndText__headline {
    grid-column: span 7;
  }
  .ImageAndText--hasHeadline .ImageAndText__textWrapper .ImageAndText__text {
    grid-column: 3 / span 5;
  }
}
@media (max-width: 1023px ) {
  .ImageAndText--hasHeadline .ImageAndText__textWrapper {
    grid-template-columns: repeat(var(--app-grid-columns), minmax(0, 1fr));
  }
  .ImageAndText--hasHeadline .ImageAndText__textWrapper .ImageAndText__headline {
    grid-column: span var(--app-grid-columns);
  }
  .ImageAndText--hasHeadline .ImageAndText__textWrapper .ImageAndText__text {
    grid-column: 3 / span 6;
  }
}
@media (max-width: 750px ) {
  .ImageAndText--hasHeadline .ImageAndText__textWrapper {
    grid-template-columns: repeat(var(--app-grid-columns), minmax(0, 1fr));
  }
  .ImageAndText--hasHeadline .ImageAndText__textWrapper .ImageAndText__headline {
    grid-column: span var(--app-grid-columns);
  }
  .ImageAndText--hasHeadline .ImageAndText__textWrapper .ImageAndText__text {
    grid-column: 2 / span 7;
  }
}
@media (min-width: 1023px ) {
  .ImageAndText--hasHeadline.ImageAndText--imagePosition-left .ImageAndText__inner {
    grid-template-areas: "a a a a . b b b b b b b";
  }
}
@media (min-width: 1023px ) {
  .ImageAndText--hasHeadline.ImageAndText--imagePosition-right .ImageAndText__inner {
    grid-template-areas: "b b b b b b b . a a a a";
  }
}
@media (min-width: 1023px ) {
  .ImageAndText__textWrapper {
    font-size: 24px;
    line-height: 28px;
  }
}
@media (max-width: 1023px ) {
  .ImageAndText__textWrapper {
    font-size: 24px;
    line-height: 28px;
  }
}
@media (max-width: 750px ) {
  .ImageAndText__textWrapper {
    font-size: 16px;
    line-height: 18px;
  }
}
.ImageAndText__headline {
  color: var(--color-rot);
}
