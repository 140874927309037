/*
@MhRouterView : {
	contentTransitionDelay : 0ms; // wofür war das gleich nochmal gut?
}
*/
.MhRouterViewWrapper {
  --MhRouterView-content-transitionDuration: 500ms;
  --MhRouterView-spinner-transitionDuration: 350ms;
  --MhRouterView-spinner-color: currentColor;
  --MhRouterView-spinner-strokeWidth: 2px;
}
.MhRouterView {
  transition-duration: calc(var(--MhRouterView-content-transitionDuration) * 0.5);
  transition-property: opacity;
  transition-timing-function: ease;
  opacity: 0;
}
.MhRouterView.fade-enter-active,
.MhRouterView.fade-leave-active {
  transition-duration: calc(var(--MhRouterView-content-transitionDuration) * 0.5);
  transition-property: opacity;
  transition-timing-function: ease;
}
.MhRouterView.fade-enter,
.MhRouterView.fade-leave-active {
  opacity: 0;
}
.MhRouterView--showContent {
  transition-delay: 50ms;
  opacity: 1;
}
.MhRouterViewWrapper__debug {
  display: none !important;
  position: fixed;
  bottom: 2em;
  left: 2em;
  padding: 0.5em;
  background-color: rgba(0, 128, 0, 0.75);
}
.MhRouterViewWrapper__spinner {
  position: fixed;
  top: 50vh;
  left: 50vw;
  height: 2em;
  width: 2em;
  transform: translateX(-50%) translateY(-50%) scale(1.2);
  pointer-events: none;
  transition: all var(--MhRouterView-spinner-transitionDuration) ease-out;
  stroke-width: var(--MhRouterView-spinner-strokeWidth);
  color: var(--MhRouterView-spinner-color);
  opacity: 0;
}
.MhRouterViewWrapper__spinner circle {
  stroke-opacity: 0.5;
}
.MhRouterViewWrapper__spinner--isVisible {
  transition-delay: 100ms;
  opacity: 0.75;
}
