.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--milka {
  --backgroundColor: rgba(79, 102, 255, 0.31);
  background-color: rgba(79, 102, 255, 0.31);
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--milka {
  --color: rgba(79, 102, 255, 0.31);
  color: rgba(79, 102, 255, 0.31);
}
.hSpace--mini {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}
.hSpace--small {
  padding-left: 0.25em;
  padding-right: 0.25em;
}
.hSpace--default {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.hSpace--medium {
  padding-left: 1rem;
  padding-right: 1rem;
}
.hSpace--large {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.vSpace--mini {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.vSpace--small {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
.vSpace--default {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.vSpace--medium {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.vSpace--large {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.vhSpace--mini {
  padding: 0.1rem;
}
.vhSpace--small {
  padding: 0.25em;
}
.vhSpace--default {
  padding: 0.5rem;
}
.vhSpace--medium {
  padding: 1rem;
}
.vhSpace--large {
  padding: 2.5rem;
}
@media (max-width: 750px ) {
}[showBorders3] .MediaWrapper {
  position: relative;
}
[showBorders3] .MediaWrapper::after {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 11px;
  line-height: 13px;
  font-weight: 400;
  border: 1px solid red;
  padding: 0.2em 0.3em;
  background-color: red;
  color: currentColor;
  pointer-events: none;
  content: attr(data-name);
  z-index: 3;
}
[showBorders3] .MediaWrapper:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 0, 0, 0.25);
  outline: 1px solid rgba(255, 0, 0, 0.65);
  pointer-events: none;
  content: "";
}
.MediaWrapper {
  position: relative;
}
.MediaWrapper__inner {
  position: relative;
  min-height: 3em;
}
.MediaWrapper__kenBurnsCarousel {
  position: absolute;
  inset: 0;
}
.MediaWrapper__image {
  position: absolute;
  inset: 0;
}
