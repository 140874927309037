.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--milka {
  --backgroundColor: rgba(79, 102, 255, 0.31);
  background-color: rgba(79, 102, 255, 0.31);
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--milka {
  --color: rgba(79, 102, 255, 0.31);
  color: rgba(79, 102, 255, 0.31);
}
.hSpace--mini {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}
.hSpace--small {
  padding-left: 0.25em;
  padding-right: 0.25em;
}
.hSpace--default {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.hSpace--medium {
  padding-left: 1rem;
  padding-right: 1rem;
}
.hSpace--large {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.vSpace--mini {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.vSpace--small {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
.vSpace--default {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.vSpace--medium {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.vSpace--large {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.vhSpace--mini {
  padding: 0.1rem;
}
.vhSpace--small {
  padding: 0.25em;
}
.vhSpace--default {
  padding: 0.5rem;
}
.vhSpace--medium {
  padding: 1rem;
}
.vhSpace--large {
  padding: 2.5rem;
}
@media (max-width: 750px ) {
}.LangSwitcher {
  display: flex;
  padding: 0 2em;
}
.LangSwitcher__btn {
  padding: 0 0.5em;
  margin-top: -0.2em;
}
.MainNav .LangSwitcher {
  color: white;
}
.App--routeName-EventView .LangSwitcher {
  color: white;
}
.App--routeName-PeopleView .LangSwitcher {
  color: white;
}
@media (min-width: 1023px ) {
  .LangSwitcher {
    font-size: 16px;
    line-height: 1em;
  }
}
@media (max-width: 1023px ) {
  .LangSwitcher {
    font-size: 12px;
    line-height: 1em;
  }
}
@media (max-width: 750px ) {
  .LangSwitcher {
    font-size: 12px;
    line-height: 1em;
  }
}
.LangSwitcher__btn {
  transition: all 0.1s ease;
  color: inherit;
  padding: 0 0.5em;
}
.LangSwitcher__btn--isInactive {
  opacity: 0.35;
}
.LangSwitcher__btn:hover {
  opacity: 1;
}
