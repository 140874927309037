/*
@import (reference) "@/less/vars.less";
@import (reference) "@/less/mixins.less";
@import (reference) "@/less/atoms.less";
*/
.MhStickyHeader__debug {
  display: none;
  position: fixed;
  left: 0;
  bottom: 25px;
  padding: 0.5em;
  font-size: 13px;
  line-height: 1.2em;
  background-color: yellow;
  z-index: 10;
}
.MhStickyHeader {
  top: 0;
  pointer-events: none;
  width: 100%;
}
.MhStickyHeader__menubar {
  top: 0;
  width: 100%;
  transform: translateY(-100%);
}
.MhStickyHeader--isReady .MhStickyHeader__menubar {
  transition: all 0.25s ease;
}
.MhStickyHeader--menubarIsVisible .MhStickyHeader__menubar {
  transform: translateY(0%);
}
.MhStickyHeader--menubarIsVisible .MhStickyHeader__menubar > * {
  pointer-events: auto;
}
.MhStickyHeader__debug {
  display: none;
}
/*
@media @mq[xs] {}
@media @mq[sm] {}
@media @mq[md] {}
@media @mq[dt] {}
@media @mq[lg] {}
@media @mq[xl] {}
*/
