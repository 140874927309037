.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--milka {
  --backgroundColor: rgba(79, 102, 255, 0.31);
  background-color: rgba(79, 102, 255, 0.31);
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--milka {
  --color: rgba(79, 102, 255, 0.31);
  color: rgba(79, 102, 255, 0.31);
}
.hSpace--mini {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}
.hSpace--small {
  padding-left: 0.25em;
  padding-right: 0.25em;
}
.hSpace--default {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.hSpace--medium {
  padding-left: 1rem;
  padding-right: 1rem;
}
.hSpace--large {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.vSpace--mini {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.vSpace--small {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
.vSpace--default {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.vSpace--medium {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.vSpace--large {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.vhSpace--mini {
  padding: 0.1rem;
}
.vhSpace--small {
  padding: 0.25em;
}
.vhSpace--default {
  padding: 0.5rem;
}
.vhSpace--medium {
  padding: 1rem;
}
.vhSpace--large {
  padding: 2.5rem;
}
@media (max-width: 750px ) {
}[showBorders3] .Card__inner {
  background-color: rgba(0, 128, 0, 0.15);
}
[showBorders3] .Card__overTitle {
  background-color: rgba(0, 128, 0, 0.15);
}
[showBorders3] .Card__ratioHelper {
  background-color: rgba(0, 128, 0, 0.15);
}
[showBorders3] .Card__card {
  background-color: rgba(0, 128, 0, 0.15);
}
[showBorders3] .Card__left {
  background-color: rgba(0, 128, 0, 0.15);
}
[showBorders3] .Card__image {
  background-color: rgba(0, 128, 0, 0.15);
}
[showBorders3] .Card__right {
  background-color: rgba(0, 128, 0, 0.15);
}
[showBorders3] .Card__text {
  background-color: rgba(0, 128, 0, 0.25);
}
[showBorders3] .Card__headline {
  background-color: rgba(0, 128, 0, 0.25);
}
[showBorders3] .Card__subHeadline {
  background-color: rgba(0, 128, 0, 0.25);
}
[showBorders3] .Card__linkWrapper {
  background-color: rgba(0, 128, 0, 0.5);
}
.Card {
  --Card-padding: 12px;
}
.Card {
  position: relative;
}
.Card--hasOverTitle {
  margin-top: 1.25em;
}
@media (min-width: 1023px ) {
  .Card__inner {
    position: absolute;
    inset: 0;
    display: grid;
    gap: var(--app-grid-gap);
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
}
@media (max-width: 1023px ) {
  .Card__inner {
    position: absolute;
    inset: 0;
    display: grid;
    gap: var(--app-grid-gap);
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
}
@media (max-width: 750px ) {
  .Card__inner {
    position: relative;
    display: flex;
    gap: initial;
    flex-direction: column;
  }
}
@media (min-width: 1023px ) {
  .Card__ratioHelper {
    position: relative;
    padding-bottom: 45%;
  }
}
@media (max-width: 1023px ) {
  .Card__ratioHelper {
    position: relative;
    padding-bottom: 45%;
  }
}
@media (max-width: 750px ) {
  .Card__ratioHelper {
    display: none;
  }
}
.Card__overTitle {
  position: absolute;
  transform: translateY(-100%);
  padding-bottom: 0.5em;
}
.Card__left {
  position: relative;
  display: flex;
  overflow: hidden;
  padding: var(--Card-padding);
}
@media (min-width: 1023px ) {
  .Card__left {
    grid-column: span 6;
    padding-right: 0;
  }
}
@media (max-width: 1023px ) {
  .Card__left {
    grid-column: span 5;
    padding-right: 0;
  }
}
@media (max-width: 750px ) {
  .Card__left {
    padding-right: var(--Card-padding);
    padding-bottom: 0;
  }
}
.Card__image,
.Card__imagePlaceholder {
  flex-grow: 1;
}
.Card .MhImage__imageWrapper--mode-cover {
  padding-bottom: initial !important;
}
.Card__right {
  padding: var(--Card-padding);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 1023px ) {
  .Card__right {
    grid-column: span 4;
  }
}
@media (max-width: 1023px ) {
  .Card__right {
    grid-column: span 3;
  }
}
@media (max-width: 750px ) {
  .Card__right {
    padding-top: 0;
  }
}
.Card__text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
}
@media (max-width: 750px ) {
  .Card__text {
    padding-top: 1.5em;
  }
}
.Card__subHeadline {
  padding-top: 0.75em;
  padding-bottom: 1em;
}
.Card__linkWrapper {
  display: flex;
  justify-content: flex-end;
}
.Card__link {
  display: flex;
  gap: 0.5em;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
}
.Card {
  color: white;
  cursor: pointer;
}
.Card:hover .Card__link {
  opacity: 1;
}
.Card__inner {
  color: inherit;
}
.Card__overTitle {
  font-size: 16px;
  line-height: 1em;
}
.Card__imagePlaceholder {
  background-color: rgba(255, 255, 255, 0.25);
}
@media (min-width: 1023px ) {
  .Card__headline {
    font-size: 40px;
    line-height: 1em;
  }
}
@media (max-width: 1023px ) {
  .Card__headline {
    font-size: 32px;
    line-height: 1em;
  }
}
@media (max-width: 750px ) {
  .Card__headline {
    font-size: 24px;
    line-height: 1em;
  }
}
@media (min-width: 1023px ) {
  .Card__subHeadline {
    font-size: 16px;
    line-height: 1em;
  }
}
@media (max-width: 1023px ) {
  .Card__subHeadline {
    font-size: 16px;
    line-height: 1em;
  }
}
@media (max-width: 750px ) {
  .Card__subHeadline {
    font-size: 16px;
    line-height: 1em;
  }
}
.Card__link {
  color: inherit;
  transition: all 0.2s ease;
  opacity: 0.65;
}
@media (min-width: 1023px ) {
  .Card__link {
    font-size: 16px;
    line-height: 1em;
  }
}
@media (max-width: 1023px ) {
  .Card__link {
    font-size: 16px;
    line-height: 1em;
  }
}
@media (max-width: 750px ) {
  .Card__link {
    font-size: 12px;
    line-height: 1em;
  }
}
@media (min-width: 1023px ) {
  .Card__linkArrow {
    font-size: 28px;
    line-height: 1em;
  }
}
@media (max-width: 1023px ) {
  .Card__linkArrow {
    font-size: 28px;
    line-height: 1em;
  }
}
@media (max-width: 750px ) {
  .Card__linkArrow {
    font-size: 28px;
    line-height: 1em;
  }
}
