.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--milka {
  --backgroundColor: rgba(79, 102, 255, 0.31);
  background-color: rgba(79, 102, 255, 0.31);
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--milka {
  --color: rgba(79, 102, 255, 0.31);
  color: rgba(79, 102, 255, 0.31);
}
.hSpace--mini {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}
.hSpace--small {
  padding-left: 0.25em;
  padding-right: 0.25em;
}
.hSpace--default {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.hSpace--medium {
  padding-left: 1rem;
  padding-right: 1rem;
}
.hSpace--large {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.vSpace--mini {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.vSpace--small {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
.vSpace--default {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.vSpace--medium {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.vSpace--large {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.vhSpace--mini {
  padding: 0.1rem;
}
.vhSpace--small {
  padding: 0.25em;
}
.vhSpace--default {
  padding: 0.5rem;
}
.vhSpace--medium {
  padding: 1rem;
}
.vhSpace--large {
  padding: 2.5rem;
}
@media (max-width: 750px ) {
}XXX[showBorders2] .MhPre__collection::before {
  position: absolute;
  top: 0;
  right: 0;
  content: attr(data-nestedLevel);
  background-color: yellow;
  padding: 0.3em;
  z-index: 1;
}
.MhPre {
  --MhPre-padding: 5px 8px 5px 7px;
  --MhPre-index-width: 2.2em;
  --MhPre-key-width: 7em;
  --MhPre-font-monospace: Consolas, monospace;
  --MhPre-font-sans: 'Roboto', 'Helvetica Neue';
  --MhPre-font-size: 12px;
  --MhPre-line-height: 14px;
  --MhPre-fg: #000000;
  --MhPre-fg-light: #595959;
  --MhPre-bg-darker: #bfbfbf;
  --MhPre-bg: #d9d9d9;
  --MhPre-bg-light: #a6a6a6;
  --MhPre-bg-white: #ffffff;
  --MhPre-border: 1px solid #737373;
  --MhPre-border-light: 1px solid #bfbfbf;
}
.MhPre {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.MhPre:not(:last-child) {
  margin: 0.5em 0;
}
.MhPre:first-child {
  margin-top: 0;
}
.MhPre__name {
  position: absolute;
  top: 0;
  right: 0;
}
.MhPre__styledData,
.MhPre__data {
  flex-grow: 1;
  overflow: auto;
}
.MhPre {
  border: 1px solid var(--color-border);
  background-color: var(--color-background);
}
.MhPre__name {
  padding: 0.25em 0.5em 0.4em;
  font-family: sans-serif;
  font-size: 12px;
  line-height: 1em;
  background-color: var(--color-border);
  color: var(--color-label);
}
.MhPre__data {
  padding: 0.5em;
  font-family: monospace;
  white-space: pre;
  color: var(--color-border);
  tab-size: 4;
}
.MhPre__header {
  display: flex;
}
.MhPre__body {
  overflow: auto;
}
.MhPre__dataName:after {
  display: inline-flex;
  padding: 0 0.65em;
  content: "•";
}
.MhPre__foldBtn {
  margin-right: 1em;
  display: inline-flex;
  color: inherit;
  align-items: center;
  justify-content: center;
  width: var(--MhPre-index-width);
  flex-grow: 0;
  height: 1em;
  width: 1em;
  transition: all 0.25s ease;
  opacity: 0.4;
  overflow: hidden;
}
.MhPre__foldBtn::before {
  content: "▸";
}
.MhPre__foldBtn::before {
  content: "▶";
}
.MhPre__foldBtn:hover {
  opacity: 0.95;
}
.MhPre__foldBtn--isActive {
  transform: rotate(90deg);
}
.MhPre__foldBtn--isDisabled {
  transform: rotate(90deg);
  pointer-events: none;
  opacity: 0.15;
}
.MhPre__collectionInfo:not( .MhPre__collectionInfo--size-0):hover .MhPre__foldBtn {
  opacity: 0.95;
}
.MhPre__collection {
  position: relative;
  XXXoverflow: hidden;
}
.MhPre__collectionItem {
  display: flex;
  overflow: hidden;
}
.MhPre__collectionInfo {
  position: sticky;
  top: 0;
  white-space: nowrap;
}
.MhPre__collectionItemKey {
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
}
.MhPre__collectionItemKey--type-array {
  width: var(--MhPre-index-width);
}
.MhPre__collectionItemKey--type-object {
  width: var(--MhPre-key-width);
}
.MhPre__collectionItemValue--type-array {
  flex-grow: 1;
  width: calc(100% - var(--MhPre-index-width));
}
.MhPre__collectionItemValue--type-object {
  flex-grow: 1;
  width: calc(100% - var(--MhPre-key-width));
}
.MhPre {
  font-size: var(--MhPre-font-size);
  line-height: var(--MhPre-line-height);
  font-family: var(--MhPre-font-sans);
  color: var(--MhPre-fg);
  border: var(--MhPre-border);
}
.MhPre__styleSwitcher {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
}
.MhPre__styleSwitcherLabel {
  padding: 0 2em;
  cursor: pointer;
  user-select: none;
  font-weight: normal;
}
.MhPre__styleSwitcherBtn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1em;
}
.MhPre__collectionInfo:not(.MhPre__collectionInfo--size-0) {
  cursor: pointer;
}
.MhPre__header {
  padding: var(--MhPre-padding);
}
.MhPre__collectionInfo {
  padding: var(--MhPre-padding);
}
.MhPre__collectionItemKey {
  padding: var(--MhPre-padding);
}
.MhPre__string {
  padding: var(--MhPre-padding);
}
.MhPre__number {
  padding: var(--MhPre-padding);
}
.MhPre__boolean {
  padding: var(--MhPre-padding);
}
.MhPre__null {
  padding: var(--MhPre-padding);
}
.MhPre__undefined {
  padding: var(--MhPre-padding);
}
.MhPre__emptyValue {
  padding: var(--MhPre-padding);
}
.MhPre .MhPre__info--brackets {
  margin-right: 1em;
  font-family: monospace;
}
.MhPre .MhPre__info--collectionSize {
  opacity: 0.35;
}
.MhPre__collectionItems:not( .MhPre__collectionItems--nestedLevel-0):not( .MhPre__collectionItems--size-0) {
  margin: 10px;
}
.MhPre__header + .MhPre__body:not(.MhPre__body:empty) {
  border-top: var(--MhPre-border);
}
.MhPre__collectionItems {
  border: var(--MhPre-border);
}
.MhPre__collectionItems--nestedLevel-0 {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: none;
}
.MhPre__collectionItem + .MhPre__collectionItem {
  border-top: var(--MhPre-border-light);
}
.MhPre__collectionInfo {
  position: relative;
}
.MhPre__collectionInfo::before {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  height: 5px;
  background: orange !important;
  background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0)) !important;
  transform: translateY(100%);
  content: "";
  z-index: 20;
}
.MhPre__body--isRaw {
  background: var(--MhPre-bg-white);
}
.MhPre__header {
  background: var(--MhPre-bg-darker);
}
.MhPre__collectionItemKey {
  background: var(--MhPre-bg);
}
.MhPre__emptyValue {
  background: var(--MhPre-bg-white);
}
.MhPre__folder {
  background: var(--MhPre-bg-white);
  background: #f2f2f2;
}
.MhPre__collectionInfo,
.MhPre__number,
.MhPre__boolean,
.MhPre__undefined,
.MhPre__string {
  background: var(--MhPre-bg-white);
}
.MhPre__header {
  font-weight: 500;
}
.MhPre__dataName {
  white-space: pre;
}
.MhPre__dataType {
  font-family: var(--MhPre-font-monospace);
}
.MhPre__number {
  font-family: var(--MhPre-font-monospace);
}
.MhPre__null {
  font-style: italic;
}
.MhPre__boolean {
  font-family: var(--MhPre-font-monospace);
}
.MhPre__undefined {
  font-family: var(--MhPre-font-monospace);
}
.MhPre__emptyValue {
  font-family: var(--MhPre-font-monospace);
}
.MhPre__collectionInfoRight {
  transition: all 0.25s ease;
  opacity: 0.35;
}
.MhPre__collectionInfo .MhPre__info--collectionSize {
  transition: all 0.25s ease;
}
.MhPre__collectionInfo:not( .MhPre__collectionInfo--size-0):hover .MhPre__info--collectionSize {
  opacity: 0.95;
}
.MhPre__number {
  color: #9933ff;
}
.MhPre__boolean--isTrue {
  color: green;
}
.MhPre__boolean--isFalse {
  color: red;
}
.MhPre__undefined {
  color: var(--MhPre-bg-dark);
}
.MhPre__stringBefore {
  color: #d65f5f;
}
.MhPre__stringText {
  color: #912525;
}
.MhPre__stringAfter {
  color: #d65f5f;
}
