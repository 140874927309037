






























































































































































































































































































.MhDevGrid { // debug
	//background-color: fade( red, 20 );
	//&__inner { background-color: fade( red, 20 ); }
	//&__col { outline: 1px solid red; }
}
.MhDevGrid { // layout
	position: fixed;
	top: 0; left: 0; right: 0;
	height: 100vh;
	z-index: 1000;
	pointer-events: none;

	&__inner {
		margin: 0 auto;
		display: grid;
		height: inherit;
		width: 100%;
		max-width: 100%;
	}
}
.MhDevGrid { // styling
	transition: all 0.1s ease;
	opacity: 0;

	&--isVisible { opacity: 1; }
}
