.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--milka {
  --backgroundColor: rgba(79, 102, 255, 0.31);
  background-color: rgba(79, 102, 255, 0.31);
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--milka {
  --color: rgba(79, 102, 255, 0.31);
  color: rgba(79, 102, 255, 0.31);
}
.hSpace--mini {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}
.hSpace--small {
  padding-left: 0.25em;
  padding-right: 0.25em;
}
.hSpace--default {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.hSpace--medium {
  padding-left: 1rem;
  padding-right: 1rem;
}
.hSpace--large {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.vSpace--mini {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.vSpace--small {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
.vSpace--default {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.vSpace--medium {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.vSpace--large {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.vhSpace--mini {
  padding: 0.1rem;
}
.vhSpace--small {
  padding: 0.25em;
}
.vhSpace--default {
  padding: 0.5rem;
}
.vhSpace--medium {
  padding: 1rem;
}
.vhSpace--large {
  padding: 2.5rem;
}
@media (max-width: 750px ) {
}[showBorders2] .MainFooter {
  position: relative;
}
[showBorders2] .MainFooter:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 0, 0, 0.25);
  outline: 1px solid rgba(255, 0, 0, 0.65);
  pointer-events: none;
  content: "";
}
[showBorders2] .MainFooter::after {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 11px;
  line-height: 13px;
  font-weight: 400;
  border: 1px solid red;
  padding: 0.2em 0.3em;
  background-color: red;
  color: currentColor;
  pointer-events: none;
  content: 'MainFooter';
  z-index: 3;
}
.MainFooter {
  position: relative;
  padding: 0.75em 0;
  overflow: visible;
  /*
	&__bgExpander {
		position: absolute;
		bottom: -100px; left: 0; right: 0;
		background-color: red;
		height: 100px;
		//transform: translateY( 100% );
	}
	*/
}
.MainFooter__inner {
  min-height: 100px;
  padding-right: var(--app-content-hPadding);
  padding-left: var(--app-content-hPadding);
  max-width: var(--app-content-maxWidth);
  margin: 0 auto;
  display: grid;
  gap: var(--app-grid-gap);
  grid-template-columns: repeat(var(--app-grid-columns), minmax(0, 1fr));
}
.MainFooter__column {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1023px ) {
  .MainFooter__column--0,
  .MainFooter__column--1 {
    grid-column: span 2;
  }
}
@media (max-width: 1023px ) {
  .MainFooter__column--0,
  .MainFooter__column--1 {
    grid-column: span 2;
  }
}
@media (max-width: 750px ) {
  .MainFooter__column--0,
  .MainFooter__column--1 {
    grid-column: span 3;
  }
}
.MainFooter__column--last {
  align-items: flex-end;
  justify-content: space-between;
}
@media (min-width: 1023px ) {
  .MainFooter__column--last {
    grid-column: span 8;
  }
}
@media (max-width: 1023px ) {
  .MainFooter__column--last {
    grid-column: span 4;
  }
}
@media (max-width: 750px ) {
  .MainFooter__column--last {
    grid-column: span 2;
  }
}
@media (min-width: 1023px ) {
  .MainFooter__miniLogo {
    height: 45px;
    width: 33px;
  }
}
@media (max-width: 1023px ) {
  .MainFooter__miniLogo {
    height: 35px;
    width: 26px;
  }
}
@media (max-width: 750px ) {
  .MainFooter__miniLogo {
    height: 35px;
    width: 26px;
  }
}
.MainFooter {
  color: white;
  background-color: var(--color-schwarz);
}
.MainFooter a {
  color: inherit;
}
.MainFooter__miniLogo {
  background-image: url('../assets/images/tmo-logo--mini.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
}
@media (min-width: 1023px ) {
  .MainFooter {
    font-size: 24px;
    line-height: 28px;
  }
}
@media (max-width: 1023px ) {
  .MainFooter {
    font-size: 24px;
    line-height: 28px;
  }
}
@media (max-width: 750px ) {
  .MainFooter {
    font-size: 16px;
    line-height: 22px;
  }
}
