.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--milka {
  --backgroundColor: rgba(79, 102, 255, 0.31);
  background-color: rgba(79, 102, 255, 0.31);
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--milka {
  --color: rgba(79, 102, 255, 0.31);
  color: rgba(79, 102, 255, 0.31);
}
.hSpace--mini {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}
.hSpace--small {
  padding-left: 0.25em;
  padding-right: 0.25em;
}
.hSpace--default {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.hSpace--medium {
  padding-left: 1rem;
  padding-right: 1rem;
}
.hSpace--large {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.vSpace--mini {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.vSpace--small {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
.vSpace--default {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.vSpace--medium {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.vSpace--large {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.vhSpace--mini {
  padding: 0.1rem;
}
.vhSpace--small {
  padding: 0.25em;
}
.vhSpace--default {
  padding: 0.5rem;
}
.vhSpace--medium {
  padding: 1rem;
}
.vhSpace--large {
  padding: 2.5rem;
}
@media (max-width: 750px ) {
}.kenBurnsDemo {
  position: relative;
  padding-bottom: 15%;
  background-color: rgba(255, 0, 0, 0.2);
}
.kenBurnsDemo > * {
  position: absolute;
  inset: 0;
}
.ShiftedMedia__content {
  display: grid;
}
.ShiftedMedia__linkText {
  display: block;
  padding-top: 1em;
}
.ShiftedMedia--countMedia-2.ShiftedMedia--isIndented .ShiftedMedia__inner {
  padding-right: var(--app-content-hPadding);
  padding-left: var(--app-content-hPadding);
  max-width: var(--app-content-maxWidth);
  margin: 0 auto;
}
.ShiftedMedia--countMedia-2.ShiftedMedia--isIndented .ShiftedMedia__content {
  display: grid;
  gap: var(--app-grid-gap);
}
.ShiftedMedia--countMedia-2.ShiftedMedia--isIndented .ShiftedMedia__column--0 {
  margin-top: 0em;
}
.ShiftedMedia--countMedia-2.ShiftedMedia--isIndented .ShiftedMedia__column--1 {
  margin-top: 3em;
}
.ShiftedMedia--countMedia-2.ShiftedMedia--isIndented .ShiftedMedia__column--0 .ShiftedMedia__media {
  width: calc(100% + (var(--app-grid-gap) * 0.5));
}
.ShiftedMedia--countMedia-2.ShiftedMedia--isIndented .ShiftedMedia__column--1 .ShiftedMedia__media {
  width: calc(100% + (var(--app-grid-gap) * 0.5));
  margin-left: calc(var(--app-grid-gap) * -0.5);
}
@media (min-width: 1023px ) {
  .ShiftedMedia--countMedia-2.ShiftedMedia--isIndented .ShiftedMedia__column--0 {
    grid-column: 3 / span 4;
  }
  .ShiftedMedia--countMedia-2.ShiftedMedia--isIndented .ShiftedMedia__column--1 {
    grid-column: span 4;
  }
  .ShiftedMedia--countMedia-2.ShiftedMedia--isIndented .ShiftedMedia__column--0 {
    grid-column: 2 / span 5;
  }
  .ShiftedMedia--countMedia-2.ShiftedMedia--isIndented .ShiftedMedia__column--1 {
    grid-column: span 5;
  }
}
@media (max-width: 1023px ) {
  .ShiftedMedia--countMedia-2.ShiftedMedia--isIndented .ShiftedMedia__column--0 {
    grid-column: span 4;
  }
  .ShiftedMedia--countMedia-2.ShiftedMedia--isIndented .ShiftedMedia__column--1 {
    grid-column: span 4;
  }
}
@media (max-width: 750px ) {
  .ShiftedMedia--countMedia-2.ShiftedMedia--isIndented .ShiftedMedia__column--0 {
    grid-column: span 4;
  }
  .ShiftedMedia--countMedia-2.ShiftedMedia--isIndented .ShiftedMedia__column--1 {
    grid-column: span 4;
  }
}
.ShiftedMedia--countMedia-2.ShiftedMedia--isFullbleed .ShiftedMedia__column--0 {
  margin-top: 0em;
}
.ShiftedMedia--countMedia-2.ShiftedMedia--isFullbleed .ShiftedMedia__column--1 {
  margin-top: 3em;
}
.ShiftedMedia--countMedia-3:first-child {
  margin-top: calc(var(--app-header-height) * -1);
}
.ShiftedMedia--countMedia-3 .ShiftedMedia__column--0 {
  margin-top: calc(var(--app-header-height));
}
.ShiftedMedia--countMedia-3 .ShiftedMedia__column--1 {
  margin-top: 0em;
}
.ShiftedMedia--countMedia-3 .ShiftedMedia__column--2 {
  margin-top: calc(var(--app-header-height));
}
.ShiftedMedia--countMedia-4 .ShiftedMedia__column--0 {
  margin-top: 3em;
}
.ShiftedMedia--countMedia-4 .ShiftedMedia__column--1 {
  margin-top: 0em;
}
.ShiftedMedia--countMedia-4 .ShiftedMedia__column--2 {
  margin-top: 3em;
}
.ShiftedMedia--countMedia-4 .ShiftedMedia__column--3 {
  margin-top: 0em;
}
.ShiftedMedia--countMedia-5 {
  /*
		.ShiftedMedia__column--0 { margin-top: 2.5em; }
		.ShiftedMedia__column--1 { margin-top: 0em; }
		.ShiftedMedia__column--2 { margin-top: 2.5em; }
		.ShiftedMedia__column--3 { margin-top: 0em; }
		.ShiftedMedia__column--4 { margin-top: 3.5em; }
		*/
}
.ShiftedMedia--countMedia-5 .ShiftedMedia__column--0 {
  margin-top: 0em;
}
.ShiftedMedia--countMedia-5 .ShiftedMedia__column--1 {
  margin-top: 3em;
}
.ShiftedMedia--countMedia-5 .ShiftedMedia__column--2 {
  margin-top: 0em;
}
.ShiftedMedia--countMedia-5 .ShiftedMedia__column--3 {
  margin-top: 3em;
}
.ShiftedMedia--countMedia-5 .ShiftedMedia__column--4 {
  margin-top: 0em;
}
.ShiftedMedia__link {
  color: inherit;
}
@media (min-width: 1023px ) {
  .ShiftedMedia__link {
    font-size: 20px;
    line-height: 1em;
  }
}
@media (max-width: 1023px ) {
  .ShiftedMedia__link {
    font-size: 16px;
    line-height: 1em;
  }
}
@media (max-width: 750px ) {
  .ShiftedMedia__link {
    font-size: 16px;
    line-height: 1em;
  }
}
