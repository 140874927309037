.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--milka {
  --backgroundColor: rgba(79, 102, 255, 0.31);
  background-color: rgba(79, 102, 255, 0.31);
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--milka {
  --color: rgba(79, 102, 255, 0.31);
  color: rgba(79, 102, 255, 0.31);
}
.hSpace--mini {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}
.hSpace--small {
  padding-left: 0.25em;
  padding-right: 0.25em;
}
.hSpace--default {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.hSpace--medium {
  padding-left: 1rem;
  padding-right: 1rem;
}
.hSpace--large {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.vSpace--mini {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.vSpace--small {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
.vSpace--default {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.vSpace--medium {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.vSpace--large {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.vhSpace--mini {
  padding: 0.1rem;
}
.vhSpace--small {
  padding: 0.25em;
}
.vhSpace--default {
  padding: 0.5rem;
}
.vhSpace--medium {
  padding: 1rem;
}
.vhSpace--large {
  padding: 2.5rem;
}
@media (max-width: 750px ) {
}[showBorders2] .MainHeader {
  position: relative;
}
[showBorders2] .MainHeader:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 0, 0, 0.25);
  outline: 1px solid rgba(255, 0, 0, 0.65);
  pointer-events: none;
  content: "";
}
[showBorders2] .MainHeader::after {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 11px;
  line-height: 13px;
  font-weight: 400;
  border: 1px solid red;
  padding: 0.2em 0.3em;
  background-color: red;
  color: white;
  pointer-events: none;
  content: "MainHeader";
  z-index: 3;
}
[showBorders3] .MainHeader {
  /*
		&__menuToggleBtn { background-color: fade( red, 50 ); }
		&__logo { background-color: fade( red, 50 ); }

		&__menu { background-color: fade( black, 75 ); outline: 1px solid fade( orange, 100 ); }
		&__menuLinks { background-color: fade( orange, 15 ); }
		&__menuLink { outline: 1px solid fade( orange, 100 ); }
		*/
}
[showBorders3] .MainHeader__row * {
  background-color: rgba(255, 0, 0, 0.25);
  outline: 1px solid rgba(255, 0, 0, 0.25);
}
.MainHeader {
  position: relative;
  height: var(--app-header-height);
  overflow: hidden;
  z-index: 100;
}
.MainHeader__row {
  display: flex;
  flex-direction: rows;
}
@media (min-width: 1023px ) {
  .MainHeader__row {
    padding: 24px 33px;
  }
}
@media (max-width: 1023px ) {
  .MainHeader__row {
    padding: 18px 16px;
  }
}
@media (max-width: 750px ) {
  .MainHeader__row {
    padding: 16px 12px;
  }
}
.MainHeader__langSwitcher {
  pointer-events: auto;
}
.MainHeader__logo {
  pointer-events: auto;
  overflow: hidden;
  text-indent: 50vw;
}
@media (min-width: 1023px ) {
  .MainHeader__logo {
    height: 50px;
    width: 140px;
  }
}
@media (max-width: 1023px ) {
  .MainHeader__logo {
    height: 40px;
    width: 105px;
  }
}
@media (max-width: 750px ) {
  .MainHeader__logo {
    height: 40px;
    width: 105px;
  }
}
.MainHeader {
  background-color: var(--color-content-background);
}
.MainHeader.MainHeader {
  pointer-events: none;
}
.MainHeader__logo {
  background-image: url('../assets/images/tmo-logo.svg');
  background-repeat: no-repeat;
  background-size: contain;
}
.MainHeader__logo {
  transition: all var(--app-view-transitionDuration) ease;
  pointer-events: auto;
}
.App--routeName-PeopleView .MainHeader .menuToggleBtn,
.App--routeName-EventView .MainHeader .menuToggleBtn {
  color: var(--color-weiss);
}
.App--routeName-PeopleView .MainHeader__logo,
.App--routeName-EventView .MainHeader__logo {
  filter: invert(100%);
}
.MainHeader__menu {
  background-color: var(--color-schwarz);
}
@media (min-width: 1023px ) {
  .MainHeader__menuLinks {
    font-size: 40px;
    line-height: 1em;
  }
}
@media (max-width: 1023px ) {
  .MainHeader__menuLinks {
    font-size: 40px;
    line-height: 1em;
  }
}
@media (max-width: 750px ) {
  .MainHeader__menuLinks {
    font-size: 40px;
    line-height: 1em;
  }
}
.MainHeader__menuLink {
  color: var(--color-weiss);
}
