.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--milka {
  --backgroundColor: rgba(79, 102, 255, 0.31);
  background-color: rgba(79, 102, 255, 0.31);
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--milka {
  --color: rgba(79, 102, 255, 0.31);
  color: rgba(79, 102, 255, 0.31);
}
.hSpace--mini {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}
.hSpace--small {
  padding-left: 0.25em;
  padding-right: 0.25em;
}
.hSpace--default {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.hSpace--medium {
  padding-left: 1rem;
  padding-right: 1rem;
}
.hSpace--large {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.vSpace--mini {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.vSpace--small {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
.vSpace--default {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.vSpace--medium {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.vSpace--large {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.vhSpace--mini {
  padding: 0.1rem;
}
.vhSpace--small {
  padding: 0.25em;
}
.vhSpace--default {
  padding: 0.5rem;
}
.vhSpace--medium {
  padding: 1rem;
}
.vhSpace--large {
  padding: 2.5rem;
}
@media (max-width: 750px ) {
}[showBorders3] .FlowText__inner {
  background-color: rgba(255, 0, 0, 0.2);
}
[showBorders3] .FlowText__rotatedHeadline {
  background-color: rgba(255, 0, 0, 0.2);
}
[showBorders3] .FlowText__rotatedHeadline span {
  background-color: rgba(255, 0, 0, 0.2);
}
[showBorders3] .FlowText__text {
  background-color: rgba(255, 0, 0, 0.2);
}
.FlowText__inner {
  padding-right: var(--app-content-hPadding);
  padding-left: var(--app-content-hPadding);
  max-width: var(--app-content-maxWidth);
  margin: 0 auto;
  display: grid;
  gap: var(--app-grid-gap);
  grid-template-columns: repeat(var(--app-grid-columns), minmax(0, 1fr));
}
@media (min-width: 1023px ) {
  .FlowText--hasNoRotatedHeadline.FlowText--textIndent-0 .FlowText__text {
    grid-column: 1 / span 6;
  }
}
@media (max-width: 1023px ) {
  .FlowText--hasNoRotatedHeadline.FlowText--textIndent-0 .FlowText__text {
    grid-column: 1 / span 6;
  }
}
@media (max-width: 750px ) {
  .FlowText--hasNoRotatedHeadline.FlowText--textIndent-0 .FlowText__text {
    grid-column: 1 / span 6;
  }
}
@media (min-width: 1023px ) {
  .FlowText--hasNoRotatedHeadline.FlowText--textIndent-2 .FlowText__text {
    grid-column: 3 / span 6;
  }
}
@media (max-width: 1023px ) {
  .FlowText--hasNoRotatedHeadline.FlowText--textIndent-2 .FlowText__text {
    grid-column: 3 / span 6;
  }
}
@media (max-width: 750px ) {
  .FlowText--hasNoRotatedHeadline.FlowText--textIndent-2 .FlowText__text {
    grid-column: 2 / span 7;
  }
}
@media (min-width: 1023px ) {
  .FlowText--hasNoRotatedHeadline.FlowText--textIndent-4 .FlowText__text {
    grid-column: 5 / span 6;
  }
}
@media (max-width: 1023px ) {
  .FlowText--hasNoRotatedHeadline.FlowText--textIndent-4 .FlowText__text {
    grid-column: 5 / span 4;
  }
}
@media (max-width: 750px ) {
  .FlowText--hasNoRotatedHeadline.FlowText--textIndent-4 .FlowText__text {
    grid-column: 3 / span 6;
  }
}
.FlowText--hasRotatedHeadline .FlowText__rotatedHeadline {
  grid-area: a;
}
.FlowText--hasRotatedHeadline .FlowText__text {
  grid-area: b;
}
@media (min-width: 1023px ) {
  .FlowText--headlinePosition-left {
    grid-template-areas: "a a b b b b b b b b b b";
  }
}
@media (max-width: 1023px ) {
  .FlowText--headlinePosition-left {
    grid-template-areas: "a a b b b b b b";
  }
}
@media (max-width: 750px ) {
  .FlowText--headlinePosition-left {
    grid-template-areas: "a b b b b b b b";
  }
}
@media (min-width: 1023px ) {
  .FlowText--headlinePosition-right {
    grid-template-areas: "b b b b b b b b b b a a";
  }
}
@media (max-width: 1023px ) {
  .FlowText--headlinePosition-right {
    grid-template-areas: "b b b b b b a a";
  }
}
@media (max-width: 750px ) {
  .FlowText--headlinePosition-right {
    grid-template-areas: "b b b b b b b a";
  }
}
.FlowText__rotatedHeadline {
  overflow: hidden;
}
.FlowText__rotatedHeadline > span {
  display: flex;
  justify-content: flex-end;
  padding: 0 0.25em;
}
.FlowText--headlinePosition-left .FlowText__rotatedHeadline > span {
  transform: rotate(-90deg) translateX(-100%);
  transform-origin: top left;
}
.FlowText--headlinePosition-right .FlowText__rotatedHeadline > span {
  transform: rotate(-90deg) translateY(-100%);
  transform-origin: top right;
}
.FlowText__rotatedHeadline {
  white-space: pre;
}
@media (min-width: 1023px ) {
  .FlowText__rotatedHeadline {
    font-size: 16px;
    line-height: 18px;
  }
}
@media (max-width: 1023px ) {
  .FlowText__rotatedHeadline {
    font-size: 16px;
    line-height: 18px;
  }
}
@media (max-width: 750px ) {
  .FlowText__rotatedHeadline {
    font-size: 12px;
    line-height: 14px;
  }
}
@media (min-width: 1023px ) {
  .FlowText__text {
    font-size: 24px;
    line-height: 28px;
  }
}
@media (max-width: 1023px ) {
  .FlowText__text {
    font-size: 24px;
    line-height: 28px;
  }
}
@media (max-width: 750px ) {
  .FlowText__text {
    font-size: 16px;
    line-height: 18px;
  }
}
