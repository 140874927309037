.background--black {
  --backgroundColor: #000000;
  background-color: #000000;
}
.background--green {
  --backgroundColor: #00DC7D;
  background-color: #00DC7D;
}
.background--blue {
  --backgroundColor: #001EE6;
  background-color: #001EE6;
}
.background--milka {
  --backgroundColor: rgba(79, 102, 255, 0.31);
  background-color: rgba(79, 102, 255, 0.31);
}
.color--black {
  --color: #000000;
  color: #000000;
}
.color--green {
  --color: #00DC7D;
  color: #00DC7D;
}
.color--blue {
  --color: #001EE6;
  color: #001EE6;
}
.color--milka {
  --color: rgba(79, 102, 255, 0.31);
  color: rgba(79, 102, 255, 0.31);
}
.hSpace--mini {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}
.hSpace--small {
  padding-left: 0.25em;
  padding-right: 0.25em;
}
.hSpace--default {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.hSpace--medium {
  padding-left: 1rem;
  padding-right: 1rem;
}
.hSpace--large {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.vSpace--mini {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}
.vSpace--small {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}
.vSpace--default {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.vSpace--medium {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.vSpace--large {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.vhSpace--mini {
  padding: 0.1rem;
}
.vhSpace--small {
  padding: 0.25em;
}
.vhSpace--default {
  padding: 0.5rem;
}
.vhSpace--medium {
  padding: 1rem;
}
.vhSpace--large {
  padding: 2.5rem;
}
@media (max-width: 750px ) {
}[showBorders3] .PeopleDetail__grid {
  background-color: rgba(255, 0, 0, 0.25);
}
[showBorders3] .PeopleDetail__grid > * {
  background-color: rgba(255, 0, 0, 0.25);
}
.PeopleDetail__inner {
  padding-right: var(--app-content-hPadding);
  padding-left: var(--app-content-hPadding);
  max-width: var(--app-content-maxWidth);
  margin: 0 auto;
}
.PeopleDetail__grid {
  display: grid;
  gap: var(--app-grid-gap);
  row-gap: 0;
  grid-template-columns: repeat(var(--app-grid-columns), minmax(0, 1fr));
}
@media (min-width: 1023px ) {
  .PeopleDetail__grid {
    grid-template-areas: "a a a a a a a a a a a a" ". . b b b b b b b b . ." ". . . . . . . . c c d d";
  }
}
@media (max-width: 1023px ) {
  .PeopleDetail__grid {
    grid-template-areas: "a a a a a a a a" ". b b b b b b ." ". . . . c c d d";
  }
}
@media (max-width: 750px ) {
  .PeopleDetail__grid {
    grid-template-areas: "a a a a a a a a" "b b b b b b b b" "c c c c d d d d";
  }
}
.PeopleDetail__bigImageWrapper {
  grid-area: a;
}
.PeopleDetail__quoteWrapper {
  grid-area: b;
}
.PeopleDetail__addressText {
  grid-area: c;
}
.PeopleDetail__contactText {
  grid-area: d;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.PeopleDetail__quote {
  color: var(--color-weiss);
}
@media (min-width: 1023px ) {
  .PeopleDetail__quote {
    font-size: 50px;
    line-height: 50px;
  }
}
@media (max-width: 1023px ) {
  .PeopleDetail__quote {
    font-size: 34px;
    line-height: 34px;
  }
}
@media (max-width: 750px ) {
  .PeopleDetail__quote {
    font-size: 24px;
    line-height: 24px;
  }
}
@media (min-width: 1023px ) {
  .PeopleDetail__addressText,
  .PeopleDetail__contactText {
    font-size: 16px;
    line-height: 18px;
  }
}
@media (max-width: 1023px ) {
  .PeopleDetail__addressText,
  .PeopleDetail__contactText {
    font-size: 16px;
    line-height: 18px;
  }
}
@media (max-width: 750px ) {
  .PeopleDetail__addressText,
  .PeopleDetail__contactText {
    font-size: 12px;
    line-height: 14px;
  }
}
.PeopleDetail__addressText a,
.PeopleDetail__contactText a {
  color: inherit;
  text-decoration: underline;
}
